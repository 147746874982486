<template>
  <div class="NewGroup">
    <div class="FormWrap">
      <span class="Infotext">{{ mixWB('NEW_GROUP_INTRO_TEXT') }}</span>
      <form @submit="onCreateClick">
        <InputField
          name="group-name"
          backgroundColor="white"
          :value="groupName"
          :placeholder="mixWB('NAME_GROUP_TEXT')"
          :disableAutoSuggest="true"
          @on-update="onGroupNameUpdate" />

        <span
          class="ErrorText"
          v-if="errorText">{{ errorText }}</span>

        <Button
          :text="mixWB('CREATE')"
          :fullWidth="false"
          @button-click="onCreateClick" />
      </form>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/FormElements/InputField'
import Button from '@/components/Buttons/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'NewGroup',
  data() {
    return {
      groupName: '',
      errorText: '',
    }
  },
  computed: {
    ...mapGetters([
      'allGroupNamesLowerCased',
    ]),
  },
  methods: {
    onGroupNameUpdate({ value }) {
      this.errorText = ''
      this.groupName = value
    },
    onCreateClick(e) {
      if (e) {
        e.preventDefault()
      }

      // Validate
      if (!this.groupName) {
        this.errorText = this.mixWB('NEW_GROUP_ERROR')
        return
      }

      if (this.allGroupNamesLowerCased.includes(this.groupName.toLowerCase())) {
        this.errorText = this.mixWB('THIS_GROUP_ALREADY_EXISTS')
        return
      }

      this.$store.dispatch('addGroup', this.groupName)

      this.$router.push({ name: 'AdminAllGroups' })
    },
  },
  components: {
    InputField,
    Button,
  },

}
</script>

<style lang="stylus" scoped>
  .NewGroup
    display block

  .Infotext
    margin-bottom 10px
    display block

  .FormWrap
    max-width 500px

  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem
</style>
